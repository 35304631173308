<template>
  <button :type="props.type" :disabled="(props.disabled || props.loading)"
    :class="[(props.loading) ? 'bg-opacity-70' : 'bg-opacity-100 hover:bg-operacolorlight', `${getButtonClass(props.size)} h-fit inline-flex items-center justify-center bg-operacolor font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-operacolor`]">
    <!-- <div v-if="props.loading" class="absolute">
      <IconLoaderCircle class="animate-spin h-5 w-5 text-white" aria-hidden="true" />
    </div> -->
    <div :class="[props.loading ? 'opacity-0' : '', 'flex items-center gap-2']">
      <component v-if="props.iconFirst && props.icon" :is="props.icon" :class="getIconSize(props.size)" aria-hidden="true" />
      {{ props.text }}
      <component v-if="!props.iconFirst && props.icon" :is="props.icon" :class="getIconSize(props.size)" aria-hidden="true" />
    </div>
  </button>
</template>


<script setup>
// Props
const props = defineProps({
  size: { type: String, required: false, default: 'md' },
  text: { type: String, required: true, default: 'Button' },
  icon: { required: false, default: null },
  disabled: { type: Boolean, required: false, default: false },
  iconFirst: { type: Boolean, required: false, default: false },
  loading: { type: Boolean, required: false, default: false },
  type: { type: String, required: false, default: 'button' },
})
// Functions
const getButtonClass = (size) => {
  if (size === 'xs') return 'rounded px-2 py-1 text-xs gap-x-2'
  if (size === 'sm') return 'rounded px-2 py-1 text-sm gap-x-2'
  if (size === 'md') return 'rounded-md px-2.5 py-1.5 text-sm gap-x-1.5'
  if (size === 'lg') return 'rounded-md px-3 py-2 text-sm gap-x-1.5'
  if (size === 'xl') return 'rounded-md px-3.5 py-2.5 text-sm gap-x-2'
}
const getIconSize = (size) => {
  if (size === 'xs') return 'size-3'
  if (size === 'sm') return 'size-4'
  if (size === 'md') return 'size-5'
  if (size === 'lg') return 'size-5'
  if (size === 'xl') return 'size-5'
}
</script>