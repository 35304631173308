<template>
  <div>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-50" @close="open = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 dark:bg-neutral-500 dark:bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white dark:bg-neutral-950 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 max-w-[calc(100vw_-_20px)] sm:max-w-[800px] w-fit h-fit sm:max-h-[calc(100vh_-_120px)] sm:p-6 overflow-y-scroll">
                <div class="absolute right-0 top-0 pr-4 pt-4 block">
                  <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 dark:text-neutral-500 dark:hover:text-neutral-400 focus:outline-none" @click="open = false">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <!-- Content -->
                <div class="flex flex-col">
                  <PartnerLogo v-if="p.id" size="large" :partner="p" class="ml-4" />
                  <!-- Forms -->
                  <form @submit.prevent="handlePartnerSubmit(p)" class="flex flex-wrap gap-10 p-5">
                    <!-- Normal Fields -->
                    <div class="w-72">
                      <label for="sector" class="!flex items-center gap-2 input_label">Title</label>
                      <div class="input_wrapper">
                        <input type="text" name="sector" class="input" placeholder="Title of the company or site" v-model="p.title" required />
                      </div>
                    </div>
                    <div class="w-72">
                      <label for="logo_text" class="!flex items-center gap-2 input_label">Logo Text
                        <InfoCircle info="This is the text that will displayed to the right of the logo, leave this blank if you do not want any text to be displayed next to the logo" />
                      </label>
                      <div class="input_wrapper">
                        <input type="text" name="logo_text" class="input" placeholder="Your Company" v-model="p.logo_text" />
                      </div>
                    </div>

                    <!-- Image Fields -->
                    <div class="flex flex-wrap gap-10 w-full">
                      <div>
                        <label for="logo" class="!flex items-center gap-2 input_label">
                          Logo Image
                          <InfoCircle info="Accepted file types png & jpeg. Files less then 10MB recommended." />
                        </label>
                        <div class="flex items-center mt-2 gap-x-3">
                          <PhotoIcon v-if="!logoUrl" class="w-12 h-12 text-gray-300" aria-hidden="true" />
                          <img v-else :src="logoUrl" alt="Logo Image" class="w-12 h-12 object-fit">
                          <label for="circle-image-upload" type="button" class="button_secondary">Change</label>
                          <input name="circle-image-upload" id="circle-image-upload" type="file" class="sr-only" accept="image/*" @change="logoImageChanged" />
                        </div>
                      </div>

                      <div>
                        <label for="background" class="!flex items-center gap-2 input_label">
                          Background Image
                          <InfoCircle info="Accepted file types png & jpeg. Files less then 10MB recommended." />
                        </label>
                        <div class="flex items-center mt-2 gap-x-3">
                          <div v-if="!backgroundUrl">
                            <PhotoIcon class="w-12 h-12 text-gray-300" aria-hidden="true" />
                          </div>
                          <img v-else :src="backgroundUrl" alt="Logo Image" class="w-24 h-12 rounded-lg object-fit">
                          <label for="background-image-upload" type="button" class="button_secondary">Change</label>
                          <input name="background-image-upload" id="background-image-upload" type="file" class="sr-only" accept="image/*" @change="backgroundImageChanged" />
                        </div>
                      </div>
                    </div>

                    <!-- Title Text Color Fields -->
                    <div class="flex flex-wrap gap-10 w-full">
                      <!-- Light Mode Title Text -->
                      <div class="w-40">
                        <label for="title_color" class="!flex items-center gap-2 input_label">
                          Title Text Color
                          <InfoCircle info="The title text color is used for title text in your site logo." />
                        </label>
                        <div class="input_wrapper">
                          <input type="color" name="title_color" class="p-1 h-10 w-14 dark:bg-transparent dark:border-neutral-700 bg-white border border-gray-200 cursor-pointer rounded-lg"
                            placeholder="#00000" v-model="p.title_color" />
                        </div>
                      </div>
                      <!-- Dark Mode Title Text -->
                      <div class="w-52">
                        <label for="title_color_dark" class="!flex items-center gap-2 input_label">
                          Dark Mode Title Text Color
                          <InfoCircle info="The title text color is used for title text in your site logo when the user is in dark mode." />
                        </label>
                        <div class="input_wrapper">
                          <input type="color" name="title_color_dark" class="p-1 h-10 w-14 dark:bg-transparent dark:border-neutral-700 bg-white border border-gray-200 cursor-pointer rounded-lg"
                            placeholder="#FFFFFF" v-model="p.title_color_dark" />
                        </div>
                      </div>
                    </div>

                    <!-- Color Fields -->
                    <div class="flex flex-wrap gap-10 w-full">
                      <div class="w-40">
                        <label for="primary_color" class="!flex items-center gap-2 input_label">
                          Primary Color
                          <InfoCircle info="The primary color is used for primary buttons, active tabs, etc." />
                        </label>
                        <div class="input_wrapper">
                          <input type="color" name="primary_color" class="p-1 h-10 w-14 dark:bg-transparent dark:border-neutral-700 bg-white border border-gray-200 cursor-pointer rounded-lg"
                            placeholder="#00000" v-model="p.primary_color" />
                        </div>
                      </div>

                      <div class="w-40">
                        <label for="primary_color_light" class="!flex items-center gap-2 input_label">
                          Light Primary Color
                          <InfoCircle info="The light primary color is used for hover states, We recommend it match you primary color but just a few shades lighter." />
                        </label>
                        <div class="input_wrapper">
                          <input type="color" name="primary_color_light" class="p-1 h-10 w-14 dark:bg-transparent dark:border-neutral-700 bg-white border border-gray-200 cursor-pointer rounded-lg"
                            placeholder="#00000" v-model="p.primary_color_light" />
                        </div>
                      </div>

                      <div class="w-40">
                        <label for="primary_color_dark" class="!flex items-center gap-2 input_label">
                          Dark Primary Color
                          <InfoCircle info="The dark primary color is used for hover states in dark mode, We recommend it match you primary color but just a few shades darker." />
                        </label>
                        <div class="input_wrapper">
                          <input type="color" name="primary_color_dark" class="p-1 h-10 w-14 dark:bg-transparent dark:border-neutral-700 bg-white border border-gray-200 cursor-pointer rounded-lg"
                            placeholder="#00000" v-model="p.primary_color_dark" />
                        </div>
                      </div>
                    </div>

                    <div>
                      <!-- Customer Support Email -->
                      <div class="w-72">
                        <label for="support_email" class="!flex items-center gap-2 input_label">Support Email</label>
                        <div class="input_wrapper">
                          <input type="text" name="support_email" class="input" placeholder="support@example.com" v-model="p.support_email" />
                        </div>
                      </div>
                    </div>

                    <!-- Boolean Fields -->
                    <div class="flex flex-col gap-5 p-5 rounded-lg w-full shadow bg-gray-100 dark:bg-neutral-800">
                      <!-- Income Data -->
                      <Switch v-model="p.collect_income_data" label="Collect Income Data" description="Collect user's income data when signing up?" />
                      <!-- Employment Data -->
                      <Switch v-model="p.collect_employer_data" label="Collect Employment Data" description="Collect user's employment data when signing up?" />
                      <!-- Allow Advisors -->
                      <Switch v-model="p.allow_advisors" label="Allow Advisors" description="Allow this partner to have advisor users?" />
                    </div>

                    <!-- Partner Type -->
                    <div class="w-40">
                      <div class="select_menu_label">Partner Type</div>
                      <SelectMenu v-model="p.partners_protected.partner_type" :items="partner_types" />
                    </div>

                    <!-- Landing Page -->
                    <div class="w-52">
                      <div class="select_menu_label">Investor Landing Pagee</div>
                      <SelectMenu v-model="p.redirect_url" :items="availableLandingTabs" />
                    </div>

                    <!-- Investor Tabs -->
                    <div class="flex flex-col p-5 rounded-lg w-full shadow bg-gray-100 dark:bg-neutral-800">
                      <legend class="input_label !flex justify-between items-center cursor-pointer" @click="showInvestorTabs = !showInvestorTabs">
                        <span class="flex items-center gap-1">
                          Investor Tabs
                          <InfoCircle info="These are tabs that an investor user will see on their dashboard." />
                        </span>
                        <PlusSmallIcon v-if="!showInvestorTabs" class="w-5 h-5" />
                        <MinusSmallIcon v-else class="w-5 h-5" />
                      </legend>
                      <div v-if="showInvestorTabs">
                        <div class="mt-4 divide-y divide-gray-200 dark:divide-neutral-600 border-b border-t border-gray-200 dark:border-neutral-600">
                          <div v-for="tab in tabs" :key="tab.id" class="relative flex items-start py-2">
                            <div class="min-w-0 flex-1 text-sm">
                              <label :for="`tab-${tab.id}`" class="select-none text-gray-700 dark:text-neutral-100 flex items-center gap-2">
                                <component :is="tab.icon" class="text-gray-500 dark:text-neutral-300 h-4 w-4 shrink-0" aria-hidden="true" />
                                {{ tab.name }}
                              </label>
                            </div>
                            <div class="ml-3 flex h-6 items-center">
                              <input :id="`tab-${tab.id}`" :name="`tab-${tab.id}`" type="checkbox" v-model="tab.selected"
                                class="h-4 w-4 rounded border-gray-300 text-operacolor focus:ring-operacolor" />
                            </div>
                          </div>
                        </div>
                        <fieldset></fieldset>
                      </div>
                    </div>

                    <!-- Advisor Tabs -->
                    <div v-if="p.allow_advisors" class="flex flex-col p-5 rounded-lg w-full shadow bg-gray-100 dark:bg-neutral-800">
                      <legend class="input_label !flex justify-between items-center cursor-pointer" @click="showAdvisorTabs = !showAdvisorTabs">
                        <span class="flex items-center gap-1">
                          Advisor Tabs
                          <InfoCircle info="These are the additional tabs that an advisor user will see on their dashboard." />
                        </span>
                        <PlusSmallIcon v-if="!showAdvisorTabs" class="w-5 h-5" />
                        <MinusSmallIcon v-else class="w-5 h-5" />
                      </legend>
                      <div v-if="showAdvisorTabs">
                        <div class="mt-4 divide-y divide-gray-200 dark:divide-neutral-600 border-b border-t border-gray-200 dark:border-neutral-600">
                          <div v-for="tab in advisor_tabs" :key="tab.id" class="relative flex items-start py-2">
                            <div class="min-w-0 flex-1 text-sm">
                              <label :for="`tab-${tab.id}`" class="select-none text-gray-700 dark:text-neutral-100 flex items-center gap-2">
                                <component :is="tab.icon" class="text-gray-500 dark:text-neutral-300 h-4 w-4 shrink-0" aria-hidden="true" />
                                {{ tab.name }}
                              </label>
                            </div>
                            <div class="ml-3 flex h-6 items-center">
                              <input :id="`tab-${tab.id}`" :name="`tab-${tab.id}`" type="checkbox" v-model="tab.selected"
                                class="h-4 w-4 rounded border-gray-300 text-operacolor focus:ring-operacolor" />
                            </div>
                          </div>
                        </div>
                        <fieldset></fieldset>
                      </div>
                    </div>

                    <!-- Admin Tabs -->
                    <div class="flex flex-col p-5 rounded-lg w-full shadow bg-gray-100 dark:bg-neutral-800">
                      <legend class="input_label !flex justify-between items-center cursor-pointer" @click="showAdminTabs = !showAdminTabs">
                        <span class="flex items-center gap-1">
                          Partner Admin Tabs
                          <InfoCircle info="These are the tabs that partner admins for this partner will see on their dashboard." />
                        </span>
                        <PlusSmallIcon v-if="!showAdminTabs" class="w-5 h-5" />
                        <MinusSmallIcon v-else class="w-5 h-5" />
                      </legend>
                      <div v-if="showAdminTabs">
                        <div class="mt-4 divide-y divide-gray-200 dark:divide-neutral-600 border-b border-t border-gray-200 dark:border-neutral-600">
                          <div v-for="tab in admin_tabs" :key="tab.id" class="relative flex items-start py-2">
                            <div class="min-w-0 flex-1 text-sm">
                              <label :for="`tab-${tab.id}`" class="select-none text-gray-700 dark:text-neutral-100 flex items-center gap-2">
                                <component :is="tab.icon" class="text-gray-500 dark:text-neutral-300 h-4 w-4 shrink-0" aria-hidden="true" />
                                {{ tab.name }}
                              </label>
                            </div>
                            <div class="ml-3 flex h-6 items-center">
                              <input :id="`tab-${tab.id}`" :name="`tab-${tab.id}`" type="checkbox" v-model="tab.selected"
                                class="h-4 w-4 rounded border-gray-300 text-operacolor focus:ring-operacolor" />
                            </div>
                          </div>
                        </div>
                        <fieldset></fieldset>
                      </div>
                    </div>

                    <!-- Meta Tag & Created -->
                    <div class="flex flex-wrap w-full gap-10 items-center">
                      <!-- Meta Tag -->
                      <div class="w-52">
                        <label for="meta_name" class="!flex items-center gap-2 input_label">
                          Meta Tag
                          <InfoCircle v-if="!p.id" info="This should be all one word, no spaces and lowercase, it will be displayed in the url on certain pages like login" />
                        </label>
                        <div class="input_wrapper">
                          <input type="text" name="meta_name" id="meta_name" v-model="p.meta_name" :disabled="(p.id ?? false)" class="input" placeholder="my_company" required />
                        </div>
                      </div>
                      <!-- Created Date-->
                      <div v-if="p.id" class="w-52">
                        <label for="created_at" class="!flex items-center gap-2 input_label">Created</label>
                        <div class="input_wrapper">
                          <input type="text" name="created_at" id="created_at" :value="formatDate(p.created_at)" :disabled="true" class="input" placeholder="Created Date" />
                        </div>
                      </div>
                    </div>

                    <!-- TAPI Credentials -->
                    <div class="w-full">

                      <Switch v-model="showTapiCredentials" label="Use Custom TAPI credentials"
                        description="If this is toggled off and empty the partner will use the default Opera credentials for eveything." />

                      <div v-if="showTapiCredentials" class="flex p-5 mt-3 rounded-lg w-full shadow bg-gray-100 dark:bg-neutral-900">
                        <div class="flex flex-wrap w-full gap-10 items-center">
                          <div class="w-36">
                            <label for="client_id" class="!flex items-center gap-2 input_label">
                              Client ID
                              <InfoCircle info="This is the client if of the new TAPI client" />
                            </label>
                            <div class="input_wrapper">
                              <input type="text" name="client_id" id="client_id" v-model="p.credentials.client_id" class="input" placeholder="Your client id" required />
                            </div>
                          </div>
                          <div class="w-52 flex-grow">
                            <label for="key" class="!flex items-center gap-2 input_label">
                              Developer Key
                              <InfoCircle info="This is the developer key for the new TAPI client, make sure this key has all endpoints enabled" />
                            </label>
                            <div class="input_wrapper">
                              <input type="text" name="key" id="key" v-model="p.credentials.key" class="input" placeholder="Your dev key" required />
                            </div>
                          </div>
                          <div class="w-36">
                            <label for="issuer_id" class="!flex items-center gap-2 input_label">
                              Issuer ID
                              <InfoCircle info="This is the issuer_id for the new TAPI client, there should only be one issuer" />
                            </label>
                            <div class="input_wrapper">
                              <input type="text" name="issuer_id" id="issuer_id" v-model="p.credentials.issuer_id" class="input" placeholder="Your dev key" required />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Advanced Settings -->
                    <div class="w-full">
                      <div class="flex items-center justify-between w-full cursor-pointer" @click="openExpanded = !openExpanded">
                        <span class="input_label !flex items-center gap-x-2">
                          Advanced Settings
                          <Cog8ToothIcon class="w-4 h-4 text-gray-500 dark:text-neutral-400" />
                        </span>
                        <PlusSmallIcon v-if="!openExpanded" class="w-5 h-5 dark:text-neutral-300" />
                        <MinusSmallIcon v-else class="w-5 h-5 dark:text-neutral-300" />
                      </div>

                      <div v-if="openExpanded" class="flex flex-col gap-5 p-5 rounded-lg w-full shadow bg-gray-100 dark:bg-neutral-900 mt-2">
                        <!-- Show Disclosure -->
                        <Switch v-model="p.show_disclosure" label="Show Disclosure" description="If this is turned off the disclosure footer will not appear for users logged in for this partner." />
                        <!-- Disable Notifications for Accreditation Status -->
                        <Switch v-model="p.send_accreditation_notifications" label="Send Accreditation Status Notifications"
                          description="If this is disabled, users will not receive emails when their accreditation status is updated." />
                        <!-- Disable KYC -->
                        <Switch v-model="p.partners_protected.kyc_disabled" label="Disable KYC & AML"
                          description="This will mean kyc and aml failures will not be run for this partner. This is primarily for DAB clients" />
                        <!-- Managed KYC AML -->
                        <Switch v-model="p.partners_protected.managed_kyc_aml" label="North Capital Managed Manual KYC & AML"
                          description="This will mean kyc and aml failures will be handled automatically by North Capital" />
                        <!-- Allow Custody Accounts -->
                        <Switch v-model="p.allow_custody_accounts" label="Allow Custody Accounts" description="Allow this partners users to create custody accounts?" />
                        <!-- Show Offering Scores -->
                        <Switch v-model="p.partners_protected.show_scores" label="Show Offering Scores" description="Allow offering scores to be visible to all investors" />
                        <!-- Show Partner Branded Scores -->
                        <Switch v-model="p.partner_scores" label="Show Partner Branded Scores" description="Allow this partner to display partner branded offering scores to their users?" />
                        <!-- Allow Advisors to see scores -->
                        <Switch v-model="p.show_scores_for_advisors" label="Show scores to Advisors" description="Allow 'Advisor' users to view offering scores?" />

                        <!-- Include Additional Links in Sign in Email -->
                        <Switch v-model="partner.use_additional_signin_content" label="Use Additional Sign in Content (Required for RegCF Offerings)"
                          description="When this is turned on the additional content you add in the 'Additional Sign in Content' text area will be available in all login and signup emails sent to your investors" />

                        <!-- Additional Content -->
                        <div v-if="partner.use_additional_signin_content" class="w-full">
                          <label for="additional-content" class="!flex items-center gap-2 input_label">
                            Additional Sign in Content
                          </label>
                          <div class="input_wrapper">
                            <textarea name="additional-content" rows="3" v-model="partner.additional_signin_content" placeholder="ex. https://operaalts.com/education" class="input"
                              required></textarea>
                          </div>
                        </div>

                        <!-- Use Custom Disclosure -->
                        <Switch v-model="p.partners_protected.use_custom_disclosure" label="Use Custom Disclosure"
                          description="If this is turned on the disclosure will be replaced with a custom one." />

                        <!-- Disclaimer -->
                        <div v-if="p.partners_protected.use_custom_disclosure" class="w-full">
                          <label for="disclosure" class="!flex items-center gap-2 input_label">Custom Disclosure</label>
                          <div class="input_wrapper">
                            <textarea name="disclosure" id="disclosure" v-model="p.partners_protected.disclosure" class="input" placeholder="Disclosure" rows="3"></textarea>
                          </div>
                        </div>

                        <!-- Allowed Sign in Methods -->
                        <div class="w-52">
                          <div class="select_menu_label">Sign in Method</div>
                          <SelectMenu v-model="signin_method" :items="allowed_signin_methods" />
                        </div>

                      </div>
                    </div>

                    <!-- Buttons -->
                    <div class="flex gap-4 justify-end w-full">
                      <button type="button" class="button_secondary" @click="open = false">Cancel</button>
                      <button type="submit" class="button_primary">
                        Save & Update
                        <LoadGifButton v-if="isUpdating" />
                      </button>
                    </div>
                  </form>
                </div>

              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
// Essentials
import { ref, watch, onMounted, computed } from 'vue';
import { supabase } from '@/lib/supabaseClient';
import { usePartnerStore } from '@/stores/partner';
// Components
import SelectMenu from '@/components/applicationui/SelectMenu.vue'
import Switch from '@/components/applicationui/Switch.vue'
import PartnerLogo from '@/components/widgets/PartnerLogo.vue'
import InfoCircle from '@/components/ui/InfoCircle.vue'
import LoadGifButton from '@/components/loading/LoadGifButton.vue'
// Libraries
import { formatDate } from '@/helper/helper';
import {
  XMarkIcon, PhotoIcon, PlusSmallIcon, MinusSmallIcon, FolderIcon, WalletIcon, CreditCardIcon, DocumentIcon, BookOpenIcon,
  FolderOpenIcon, UsersIcon, UserCircleIcon, CurrencyDollarIcon, CubeTransparentIcon, WrenchScrewdriverIcon, Square3Stack3DIcon, ShieldExclamationIcon, Cog6ToothIcon, NewspaperIcon
} from '@heroicons/vue/24/outline'
import { Cog8ToothIcon, GlobeAmericasIcon } from '@heroicons/vue/24/solid'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
// Props
const props = defineProps({
  partner: {
    type: Object,
    required: true,
    default: null
  },
});
// Emits
const emit = defineEmits(['closed', 'refresh']);
// Stores
const partnerStore = usePartnerStore();
// Globals
const p = ref({});
const open = ref(true);
const openExpanded = ref(false);
const showTapiCredentials = ref(false)
const showInvestorTabs = ref(false);
const showAdvisorTabs = ref(false);
const showAdminTabs = ref(false);
const isUpdating = ref(false);
const logoUrl = ref(null);
const logoImage = ref(null);
const backgroundUrl = ref(null);
const backrgoundImage = ref(null);
const partner_types = ['dib', 'dab', 'maas', 'custody', 'mixed'];
// Tabs
const tabs = ref([
  { id: 1, name: 'Invest', href: '/dashboard/investing', icon: GlobeAmericasIcon, selected: true },
  { id: 6, name: 'Accounts', href: '/dashboard/accounts', icon: FolderIcon, selected: false },
  { id: 2, name: 'Holdings', href: '/dashboard/holdings', icon: WalletIcon, selected: true },
  { id: 3, name: 'Payment Methods', href: '/dashboard/payment-methods', icon: CreditCardIcon, selected: true },
  { id: 4, name: 'Documents', href: '/dashboard/documents', icon: DocumentIcon, selected: true },
  { id: 5, name: 'Education', href: '/dashboard/education', icon: BookOpenIcon, selected: true },
]);
const advisor_tabs = ref([
  { id: 11, name: 'Managed Accounts', href: '/dashboard/managed-accounts', icon: FolderOpenIcon, selected: true },
  { id: 12, name: 'Clients', href: '/dashboard/parties', icon: UsersIcon, selected: true },
]);
const admin_tabs = ref([
  { id: 21, name: 'Users', href: '/dashboard/users', icon: UserCircleIcon, selected: true },
  { id: 22, name: 'Investors', href: '/dashboard/investors', icon: UsersIcon, selected: true },
  { id: 23, name: 'Transactions', href: '/dashboard/admin-transactions', icon: CurrencyDollarIcon, selected: true },
  { id: 24, name: 'Offerings', href: '/dashboard/offerings', icon: CubeTransparentIcon, selected: true },
  { id: 25, name: 'Site Settings', href: '/dashboard/site-settings', icon: WrenchScrewdriverIcon, selected: true },
  { id: 26, name: 'Custody Accounts', href: '/dashboard/custody', icon: Square3Stack3DIcon, selected: false },
  { id: 27, name: 'Investor Verification', href: '/dashboard/investor-verification', icon: ShieldExclamationIcon, selected: false },
  { id: 28, name: 'Articles Editor', href: '/dashboard/articles', icon: NewspaperIcon, selected: true },
]);
const availableLandingTabs = computed(() => {
  let t = tabs.value.filter(tab => tab.selected)
  if (t.some(tab => tab.id === 0)) return t.map(tab => tab.href)
  else return t.concat({ id: 0, name: 'Settings', href: '/dashboard/settings', icon: Cog6ToothIcon, selected: true }).map(tab => tab.href)
});

// Mounted
onMounted(async () => {
  p.value = props.partner;
  console.log(p.value)
  setSelectedTabs();
  logoUrl.value = await getUrl(p.value.meta_name, 'logo');
  backgroundUrl.value = await getUrl(p.value.meta_name, 'background');

  if (p.value.partners_protected.allowed_signin_methods && p.value.partners_protected.allowed_signin_methods[0] == 'otp') signin_method.value = allowed_signin_methods.value[1]
});

// Functions
async function getUrl(meta_name, fileName) {
  const { data, error } = await supabase.storage.from('partners').list(`${meta_name}`, { limit: 1, search: `${fileName}` });
  if (error || data.length <= 0) return null;
  const { data: publicUrl } = supabase.storage.from('partners').getPublicUrl(`${meta_name}/${fileName}`);
  return publicUrl.publicUrl;
};

async function handlePartnerSubmit(partner) {
  // TODO this could be faster with some async optimizations
  isUpdating.value = true;
  // Update Partner
  let id;
  if (partner.id) {
    id = partner.id
    await partnerStore.updatePartnerFull(partner)
  }
  // Create Parnter
  else {
    id = await partnerStore.createPartnerFull(partner)
  }

  // Update & Create Partner
  partner = await setPartnersProtected(partner)
  console.log(partner)
  await partnerStore.updatePartnersProtected(id, partner.partners_protected)
  await partnerStore.updateCredentials(id, partner.credentials)

  // Upload Images
  if (logoImage.value) await partnerStore.uploadPartnerImages(partner.meta_name, logoImage.value, 'logo')
  if (backrgoundImage.value) await partnerStore.uploadPartnerImages(partner.meta_name, backrgoundImage.value, 'background')

  // Reset
  logoUrl.value = null;
  logoImage.value = null;
  backgroundUrl.value = null;
  backrgoundImage.value = null;

  // Close and refresh
  isUpdating.value = false;
  emit('refresh');
  open.value = false;
};

// Functions Simple
const logoImageChanged = (event) => {
  if (event.target.files[0].type != 'image/jpeg' && event.target.files[0].type != 'image/png') {
    logoUrl.value = {};
    logoImage.value = null;
  } else {
    logoImage.value = event.target.files[0];
    logoUrl.value = URL.createObjectURL(event.target.files[0]);
  }
};

const backgroundImageChanged = (event) => {
  if (event.target.files[0].type != 'image/jpeg' && event.target.files[0].type != 'image/png') {
    backgroundUrl.value = {};
    backrgoundImage.value = null;
  } else {
    backrgoundImage.value = event.target.files[0];
    backgroundUrl.value = URL.createObjectURL(event.target.files[0]);
  }
};

const setSelectedTabs = () => {
  if (p.value.partners_protected.investor_tabs) {
    tabs.value.forEach(tab => {
      if (p.value.partners_protected.investor_tabs.includes(tab.id)) tab.selected = true;
      else tab.selected = false;
    });
  }
  if (p.value.partners_protected.advisor_tabs) {
    advisor_tabs.value.forEach(tab => {
      if (p.value.partners_protected.advisor_tabs.includes(tab.id)) tab.selected = true;
      else tab.selected = false;
    });
  }
  if (p.value.partners_protected.admin_tabs) {
    admin_tabs.value.forEach(tab => {
      if (p.value.partners_protected.admin_tabs.includes(tab.id)) tab.selected = true;
      else tab.selected = false;
    });
  }
};

const setPartnersProtected = async (partner) => {
  partner.partners_protected.investor_tabs = tabs.value.filter(tab => tab.selected && tab.id).map(tab => tab.id);
  partner.partners_protected.advisor_tabs = advisor_tabs.value.filter(tab => tab.selected && tab.id).map(tab => tab.id);
  partner.partners_protected.admin_tabs = admin_tabs.value.filter(tab => tab.selected && tab.id).map(tab => tab.id);
  partner.partners_protected.allowed_signin_methods = [signin_method.value.id]
  return partner
};

// Allowed Signin Methods

const allowed_signin_methods = ref([
  { id: 'magiclink', label: 'Magic Link' },
  { id: 'otp', label: 'One Time Password' }
])
const signin_method = ref(allowed_signin_methods.value[0]);

// Watchers
watch(open, async () => {
  if (!open.value) {
    await new Promise(resolve => setTimeout(resolve, 400));
    emit('closed');
  }
});
// Watch the tabs array, if it changes update the availableLandingTabs array with the hrefs of the selected tabs
// watch(tabs, () => {
//   availableLandingTabs = tabs.value.filter(tab => tab.selected).map(tab => tab.href);
// });
</script>